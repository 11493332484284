.paginator {
	width: 70%;
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	padding: 3rem 0;
	cursor: pointer;
	font-size: 1.3rem;

	&__hide {
		display: none;
	}

	img {
		width: 3rem;
	}

	&__control {
		padding: 1rem 2rem;
		background-color: transparent;
		font-size: 1.6rem;
		border: solid;
		border: solid 0.1rem $color-blue-1;
		border-radius: 0.3rem;

		&:hover,
		&--active {
			background: $color-blue-1;
			color: $color-white;
		}
	}
}
.show {
	display: block;
}
