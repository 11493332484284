.transaction {
  width: 100%;
  padding-bottom: 3rem;
  margin: auto;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 7rem;

  &__response {
    position: absolute;
    width: auto;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    top: 7%;
    right: 0;
    // display: none;
    background-color: transparent;
    cursor: pointer;
    color: transparent;
    transition: all 300ms;
    border-radius: 4px 0 0 4px;

    &--error,
    &--success {
      color: $color-white;
    }

    &--error {
      background-color: $color-red-1;
    }

    &--success {
      background-color: $color-blue-3;
    }
  }

  & > * {
    min-width: 0;
    min-height: 0;
  }

  &__cards {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &__transactions {
    display: grid;
    gap: 3rem;
    grid-template-rows: auto 1fr;
  }

  &__actions {
    display: flex;
    gap: 2rem;
    justify-content: space-between;

    @include maxSize(M) {
      flex-direction: column;
    }
  }

  &__view {
    flex: 0 1 45%;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 4fr 1fr;
  }

  &__search,
  &__filter {
    position: relative;
    padding: 1.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 4.4rem;
    border-radius: 0.8rem;
    background-color: $color-white;
    font-size: 1.4rem;
  }
  &__search {
    border: solid 1px $color-text-gray-3;
  }

  &__select {
    background: transparent;
    border: transparent;
    outline: none;
    outline: none;
    font-size: 1.5rem;
    color: $color-white;

    option {
      color: $color-text-gray-4;
    }
  }

  &__filter {
    background: $color-blue-3;
    width: max-content;
  }

  &__icon {
    &--search {
      content: url($imagePath + "/search.svg");
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    outline: transparent;
    border: transparent;
  }

  .filterDropdown {
    position: absolute;
    top: 120%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    list-style: none;
    padding: 12px 14px;
    margin: 0;
    width: 30dvw;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $color-bg-gray-3;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include maxSize(S) {
      top: 0;
      bottom: 0;
      position: fixed;
      width: 100dvw;
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: solid 1px $color-bg-gray-1;

      p {
        font-weight: 500;
        font-size: 20px;
        color: $color-text-gray-2;
      }
    }

    .filterDate {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      gap: 5px;
      position: relative;
      p {
        font-weight: 500;
        // font-family: "DM Sans" !important;
        size: 14px;
        color: $color-text-gray-2;
      }

      .dateBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 12px;
        border: solid 1px $color-bg-gray-2;
      }
    }

    /* Overlay styles */
    .calendar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
    }

    /* Calendar container styles */
    .calendar-container {
      background: white;
      padding: 10px;
      border-radius: 15px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      height: 500px;
      overflow-y: scroll;
      width: 28%;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      @include maxSize(S) {
        width: 90%;
      }

      .calendar-title {
        color: $color-black;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .rdp-month_caption {
        justify-content: center;
        align-items: center;
      }
      th,
      td {
        padding: 0;
        font-weight: 700;
        size: 18px;
        color: $color-black;
        text-align: center;
      }
      td:last-child,
      th:last-child,
      th:nth-last-child(2),
      td:nth-last-child(2) {
        align-items: center;
        color: $color-red-1;
      }
      td:last-child,
      th:last-child {
        display: flex;
        justify-content: center;
      }
      .rdp-months {
        display: block;
        width: 100%;
        // .rdp-chevron {
        //   display: none;
        // }
      }
      .rdp-day:hover,
      .rdp-day:active,
      .rdp-selected {
        background: $color-blue-3;
        font-weight: bold;
        font-size: large;
      }

      .rdp-month_grid {
        padding-top: 10px;
      }

      .rdp-caption_label {
        font-weight: 700;
        font-size: 20px;
        color: $color-black;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.3rem;
    overflow-x: auto;
  }
  thead {
    border-bottom: solid 1px $color-bg-gray-3;
  }
  tr {
    background-color: $color-white;
  }

  th,
  td {
    padding: 1rem 1.6rem;
    position: relative;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 400;
    word-wrap: break-word;
    overflow-wrap: anywhere;

    @include screenSize(S) {
      padding: 1rem 0.5rem;
    }
  }
  th {
    color: $color-black;
  }
  td {
    color: $color-text-gray-4;

    &:last-child {
      font-size: 1.4rem;
      cursor: pointer;
    }
  }
}
