@import "react-datepicker/dist/react-datepicker.css";

$imagePath: "../../../public/images";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");

@import "abstracts/variables";
@import "abstracts/mixins";

//base styles
@import "base/base";

//styles for layout
@import "layout/lawswrapper";
@import "layout/admindashboard";
@import "layout/topnav";

@import "components/datepicker";
@import "components/hero";
@import "components/paginator";
@import "components/loading";
@import "components/overview-card";
@import "components/adminsidemenu";
@import "components/overviewoptions";
@import "components/overlay";
@import "components/newusermodal";
@import "components/userfiltermodal";
@import "components/userstatelaws";
@import "components/loader";
@import "components/noData";

@import "pages/login";
@import "pages/statelaws";
@import "pages/lawdetails";
@import "pages/adminclient";
@import "pages/admintransactions";
@import "pages/adminoverview";

@import "vendors/react_date_picker";
.api-response {
  color: $color-baby-blue;
}
