// NoData.scss

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%; // Adjust based on your layout
  text-align: center;
  color: $color-bg-gray-3; // Muted text color
  font-family: "Arial", sans-serif;

  .no-data-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  .no-data-message {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
