.userStateLaws {
  width: 100%;
  padding: 4rem 2rem;
  background-color: $color-white;
  border-radius: 10px;
  color: $color-black;

  table {
    width: 100%;
    font-size: 1.2rem;
    thead {
    //   border-bottom: solid 1px $color-text-gray-4;
      th {
        font-weight: 600;
        font-size: 1.2rem;
        border-bottom: solid 1px $color-bg-gray-2;
      }
    }

    tr {
      td {
        border-bottom: solid 1px $color-bg-gray-2;
        font-size: 1.5rem;
      }
    }
  }
}
