.client {
  width: 100%;
  padding-bottom: 3rem;
  margin: auto;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 7rem;

  &__response {
    position: absolute;
    width: auto;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    top: 7%;
    right: 0;
    // display: none;
    background-color: transparent;
    cursor: pointer;
    color: transparent;
    transition: all 300ms;
    border-radius: 4px 0 0 4px;

    &--error,
    &--success {
      color: $color-white;
    }

    &--error {
      background-color: $color-red-1;
    }

    &--success {
      background-color: $color-blue-3;
    }
  }

  & > * {
    min-width: 0;
    min-height: 0;
  }

  &__cards {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &__users {
    display: grid;
    gap: 3rem;
    grid-template-rows: auto 1fr;
  }

  &__actions {
    display: flex;
    gap: 2rem;
    justify-content: space-between;

    @include maxSize(M) {
      flex-direction: column;
    }
  }

  &__view {
    flex: 0 1 45%;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 4fr 1fr;
  }

  &__search,
  &__filter {
    position: relative;
    padding: 1.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 4.4rem;
    border: solid 1px $color-text-gray-3;
    border-radius: 0.8rem;
    background-color: $color-white;
    font-size: 1.4rem;
  }

  &__select {
    background: transparent;
    border: transparent;
    outline: none;
    font-size: 1.5rem;
    width: 173px;
    font-size: 1.4rem;
    color: $color-text-gray-4;
    box-shadow: none;

    &--option {
      font-weight: 400;
      font-size: 1.6rem;
      padding: 3000px;
    }
    &--option1 {
      border-bottom: 1px solid $color-bg-gray-3;
      background: #000;

      //   height: ;
      padding: 20px 300px;
    }
  }

  &__icon {
    &--search {
      content: url($imagePath + "/search.svg");
    }

    &--export {
      padding: 0.5rem;
      background: rgba(255, 255, 255, 0.24);
      content: url($imagePath + "/export.svg");
      border-radius: 0.3rem;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    outline: transparent;
    border: transparent;
  }

  &__export {
    width: max-content;
    padding: 0.8rem 1.2rem;
    display: flex;
    align-items: center;
    gap: 1.23rem;
    color: $color-white;
    border-radius: 0.4rem;
    font-size: 1.5rem;
    background-color: $color-blue-3;
    cursor: pointer;
    position: relative;
  }
  .dropdown {
    position: absolute;
    top: 120%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 110px;
    z-index: 10;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: $color-bg-gray-3;
    text-align: center;
  }

  .dropdown li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .dropdown li:hover {
    background-color: #f0f0f0;
  }
  &__paginator {
    display: flex;
    align-items: center;
    gap: 10px;

    select {
      padding: 8px 12px;
      border-radius: 5px;
      border: $color-text-gray-3;
    }
  }
}
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.3rem;
  overflow-x: auto;
}
thead {
  border-bottom: solid 1px $color-bg-gray-3;

  .states {
    text-align: center;
  }
}
tr {
  background-color: $color-white;
  .states {
    text-align: center;
  }
  .view {
    background-color: $color-blue-3;
    color: $color-white;
    height: 32px;
    width: 94px;
    padding: 4px 23px;
    font-size: 1.5rem;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

th,
td {
  padding: 1rem 1.6rem;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: anywhere;

  @include screenSize(S) {
    padding: 1rem 0.5rem;
  }
}
th {
  color: $color-black;
}
td {
  color: $color-text-gray-4;

  &:last-child {
    font-size: 3rem;
    cursor: pointer;
    display: flex;
  }
}
